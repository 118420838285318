<template>
  <section id="report-filter-wrapper">
    <label>{{ lang.metrics.filters.report.label[languageSelected] }}</label>

    <multiselect
      class="report-filter"
      v-if="reportsOptions.length"
      v-model="value"
      :options="reportsOptions"
      :label="languageSelected"
      track-by="name"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="lang.metrics.filters.report.placeholder[languageSelected]"
      :selected-label="lang.filters.report.selectedLabel[languageSelected]"
      :deselect-label="lang.filters.report.deselectLabel[languageSelected]"
      :show-labels="false"
      @input="change"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span class="multiselect__single" v-if="values.length && !isOpen">
          {{ values.length }}
          {{ lang.metrics.filters.report.selected[languageSelected] }}
        </span>
      </template>
    </multiselect>

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReportFilter',
  data() {
    return {
      value: [],
      reportsOptions: []
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    change() {
      const value = this.value.length > 0 ? this.value : this.options
      this.$emit('change', {
        type: 'report',
        value: value
      })
    },
    reset() {
      this.value = []
    },
    clear() {
      this.reset()
      this.change()
    }
  },
  mounted() {
    this.reportsOptions = this.options
    this.$emit('init', {
      type: 'report',
      value: this.reportsOptions
    })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#report-filter-wrapper {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }

  .report-filter {
    min-width: 200px;
    width: 320px;
  }
}
</style>
